<template>
  <div class="bottomFixed">
    <ul class="listInline">
      <li class="listItem">
        <a href="javascript: void(0);" @click="openSettings()"> SETTINGS </a>
      </li>
      <li class="listItem">
        <a href="javascript: void(0);" @click="openSupport()"> SUPPORT </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    openSettings() {
      this.$router.push("/settings");
    },

    openSupport() {
      this.$router.push("/support");
    },
  },
};
</script>

<style scoped lang="scss">
.bottomFixed {
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: center;
  .listInline a {
    color: #1c3766;
  }
}

.listInline {
  list-style: none;
  padding-left: 0;
  font-size: 14px;
  margin: 1rem 0px;
  .listItem {
    display: inline-block;
    margin-right: 1rem;
    a {
      text-decoration: none;
    }
  }
  .listItem:last-child {
    margin-right: 0rem;
  }
}
</style>
